@import 'styles/includes';

.FormInputField {
    $root: &;
    position: relative;

    &__Label {
        @extend %label;
        margin: 0 -4px;
        padding: 2px 4px 0;
        background-color: $colorWhite;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: absolute;
        top: 30px;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:
            top $transitionDelayFast $ease,
            left $transitionDelayFast $ease;
        pointer-events: none;

        #{$root}--Focused & {
            top: 0;
            background-color: $colorLichen;
        }

        #{$root}--Filled & {
            top: 0;
            transition: none;
        }

        #{$root}--Error & {
            color: $colorError;
        }

        #{$root}--Disabled & {
            background-color: $colorGrey-10;
            color: $colorGrey-40;
        }

        #{$root}--DisabledAlternative & {
            background-color: $colorWhite;
            color: $colorLightBlue;
        }

        #{$root}--LargeLabel & {
            font-family: $fontBold;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            display: inline-block;
            position: relative;
            left: 10px;
            transition: none;
        }

        &::after {
            #{$root}--Required & {
                content: '*';
                color: $colorError;
                position: relative;
            }
        }
    }

    &__LabelInner {
        position: relative;
    }

    &__Input {
        @extend %p;
        width: 100%;
        margin-top: 8px;
        padding: 16px 20px;
        letter-spacing: 1px;
        color: $colorBlue;
        border-radius: 8px;
        border: 1px solid $colorBlue;

        @include media-max(s) {
            font-size: 1.6rem;
        }

        &:focus {
            background-color: $colorLichen;
        }

        &:disabled,
        #{$root}--Disabled & {
            background-color: $colorGrey-10;
            border: 1px solid $colorGrey-30;
            color: $colorGrey-40;
        }

        #{$root}--DisabledAlternative & {
            background-color: $colorWhite;
            color: $colorLightBlue;
            border: 1px solid $colorLightBlue;
        }

        &::placeholder {
            font-family: $fontBody;
            font-weight: 400;
        }

        #{$root}--Error & {
            font-family: $fontBold;
            font-weight: 700;
            color: $colorError;
        }

        #{$root}--LargeLabel & {
            font-family: $fontBold;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.6rem;
            letter-spacing: 0.01rem;
        }
    }

    &__HelpText {
        @extend %help-text;

        #{$root}--Error & {
            color: $colorError;
        }
    }
}
