@import 'styles/includes';

.CardOffer {
    $root: &;

    background-color: $colorWhite;
    border: 1px solid $colorBlue;
    border-radius: 8px;
    width: 100%;
    padding-bottom: 28px;
    margin-bottom: 28px;
    position: relative;
    box-shadow: 0 0 0 0 $colorYellow;
    transition: box-shadow $transition;

    &--Hover {
        box-shadow: 11px -11px 0px 0px $colorYellow;
    }

    &--Highlight {
        @include media-max(ml) {
            grid-column: -1/1;
        }
    }

    &__Header {
        background-color: $colorLichen;
        border-radius: 8px 8px 0 0;
        padding: 17px 17px 15px 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: wrap-reverse;
    }

    &__Label {
        margin-bottom: 6px;
        font-size: 1.2rem;
        font-family: $fontBold;
        text-transform: uppercase;
        letter-spacing: 0.0496rem;
        color: $colorBlue;
    }

    &__Title {
        @extend %h3;
        color: $colorBlue;
    }

    &__Tag {
        margin-left: auto;
        margin-bottom: 1rem;
        padding: 0.5rem 2.2rem 0.5rem 1rem;
        background-color: $colorYellow;
        border-radius: 6rem;
        border-color: transparent;
        display: inline-block;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.031rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(s) {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            margin-right: 14px;
            min-width: 10px;
            width: 10px;
            height: 10px;
            background-color: $colorLichen;
            border-radius: 100%;
            display: inline-block;
            position: relative;
        }

        #{$root}--Small & {
            padding: 0.5rem 1rem 0.5rem 1rem;
            width: 87px;
            height: 87px;
            white-space: pre-wrap;
            text-align: center;
            position: absolute;
            top: -20px;
            right: -11px;

            &::before {
                display: none;
            }
        }
    }

    &__Content {
        padding: 10px 18px 25px;

        @include media(m) {
            padding: 20px 3rem 1.6rem;
        }
    }

    &__Text {
        @extend %p;
        padding: 0 0 11px;
        margin: 0 0 11px;
        border-bottom: 1px solid $colorYellow;
        display: inline-block;
        width: 100%;
        word-break: break-word;
        hyphens: manual;

        @include media(m) {
            padding: 0 0 20px;
            margin: 0 0 20px;
        }
    }

    &__List {
        display: grid;

        grid-gap: 10px;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $colorContrast;
        word-break: break-word;
        hyphens: auto;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
        }

        #{$root}--Small & {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__ListItem {
        padding-left: 18px;
        position: relative;

        @include media(m) {
            padding-left: 23px;
        }

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: $colorYellow;
            display: inline-block;
            border-radius: 16px;
            position: absolute;
            left: 0;
            top: 0.6496rem;

            @include media(m) {
                top: 0.7504rem;
            }
        }
    }

    &__ButtonContainer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -28px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Button {
        background-color: $colorWhite;
        border-radius: 60px;
        border-color: transparent;
        display: inline-block;
        font-family: $fontBold;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 2.2rem;
        text-transform: uppercase;
        white-space: nowrap;
        border: 2px solid $colorBlue;
        color: $colorBlue;
        padding: 16px 40px 14px;
        transition:
            color $transition,
            background-color $transition;

        &:hover {
            background-color: $colorBlue;
            color: $colorWhite;
        }

        &:focus-visible {
            outline: $colorYellow solid 3px;
        }
    }
}
