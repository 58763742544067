@import 'styles/includes';

.CardPricing {
    $root: &;

    margin: 26px 0 0 0;

    position: relative;
    display: flex;
    flex-direction: column;

    &__Badge {
        position: absolute;
        transform: translate(8px, -27px);
        top: 0;
        right: 0;

        &:empty {
            display: none;
        }
    }

    &__Header {
        padding: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background-color: $colorLichen;
        border-top: 1px solid $colorBlue;
        border-right: 1px solid $colorBlue;
        border-left: 1px solid $colorBlue;
        border-radius: 8px 8px 0 0;

        #{$root}--SignupLink & {
            padding: 16px 34px;
        }
    }

    &__Title {
        @extend %h3;
        color: $colorBlue;
    }

    &__Label {
        margin-left: 44px;
        font-size: 1.2rem;
        font-family: $fontBold;
        text-transform: uppercase;
        letter-spacing: 0.0496rem;
        line-height: 2.4rem;
        color: $colorBlue;

        #{$root}--SignupLink & {
            margin-left: 0;
        }
    }

    &__Radio {
        width: 100%;
        position: relative;
        display: inline-flex;

        #{$root}--SignupLink & {
            padding-left: 0;
            cursor: auto;
        }
    }

    &__Input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__ControlIndicator {
        width: 2.8rem;
        height: 2.8rem;
        display: block;
        border: 2px solid $colorBlue;
        border-radius: 50%;
        background-color: $colorWhite;
        background-size: 1.5rem;
        background-position: center center;
        background-repeat: no-repeat;
        user-select: none;
        margin-top: 0.5rem;
        margin-right: 1.4rem;
        flex-shrink: 0;

        #{$root}--SignupLink & {
            display: none;
        }
    }

    &__Input:focus ~ #{$root}__ControlIndicator {
        box-shadow:
            0 0 0 0.075rem $colorWhite,
            0 0 0 0.2rem #0074d9;
    }

    &__Input:checked ~ #{$root}__ControlIndicator {
        background-color: $colorBlue;
    }

    &__Input:active ~ #{$root}__ControlIndicator {
        background-color: $colorBlue;
    }

    &__Input:checked ~ #{$root}__ControlIndicator {
        background-image: url('/img/check-white.svg');
    }

    &__Content {
        height: 100%;
        padding: 18px 16px;
        color: $colorContrast;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        border-right: 1px solid $colorBlue;
        border-bottom: 1px solid $colorBlue;
        border-left: 1px solid $colorBlue;
        border-radius: 0 0 8px 8px;
        background-color: $colorWhite;

        @include media(s) {
            padding: 18px 34px;
        }

        #{$root}--Checked & {
            background-color: $colorBlue;
            color: $colorWhite;
        }

        #{$root}--SignupLink & {
            padding: 18px 16px 50px;
        }
    }

    &__Price {
        @extend %p;
        width: 100%;
        margin: 0 0 8px auto;
        text-align: right;

        @include media(xs) {
            margin: 0 0 0 auto;
            width: auto;
        }
    }

    &__List {
        @extend %ul;
        padding-bottom: 18px;
        margin-bottom: 18px;
        width: 100%;
        border-bottom: 1px solid $colorYellow;
    }

    &__InfoButton {
        @extend %h5;
        padding: 0;
        color: $colorBlue;
        background-color: transparent;
        border: 0;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;

        #{$root}--Checked & {
            color: $colorWhite;
        }
    }

    &__Icon {
        margin-left: 9px;
        width: 10px;
        height: 5px;

        #{$root}__InfoButton--Active & {
            transform: rotate(180deg);
        }
    }

    &__PriceKwh {
        margin-bottom: 8px;
        color: $colorContrast;
        display: block;

        #{$root}--Checked & {
            color: $colorWhite;
        }
    }

    &__Specification {
        width: 100%;
        display: none;

        &--Expanded {
            display: block;
        }
    }

    &__TableTitle {
        @extend %h5;
        margin-bottom: 8px;
        color: $colorBlue;

        #{$root}--Checked & {
            color: $colorWhite;
        }
    }

    &__Table {
        @extend %p;
        width: 100%;
    }

    &__TableRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &--Bold {
            font-weight: 700;
        }
    }

    &__TableCell {
        @include media(m) {
            padding: 4px 0;
        }

        &--Left {
            padding-right: 8px;
            flex-shrink: 1;
        }

        &--Right {
            padding-left: 8px;
            text-align: right;
            flex-shrink: 1;
        }
    }

    &__TableCellDivider {
        margin-top: auto;
        margin-bottom: 7px;
        flex-grow: 1;
        border-bottom: 1px solid #d9d9d9;

        #{$root}--Checked & {
            border-color: #82afbb;
        }

        @include media(m) {
            margin-bottom: 11px;
        }
    }

    &__Link {
        @extend %small;
        margin: 12px 0 0 0;
        text-decoration: underline;
        display: inline-block;
    }

    &__Text {
        margin-top: 16px;
        width: 100%;

        p {
            @extend %small;
            margin-bottom: 8px;
        }
    }

    &__Button {
        @extend %small;
        padding: 0;
        font-family: $fontBold;
        font-weight: 600;
        text-decoration-color: $colorBlue;
        text-decoration-line: underline;
        text-underline-offset: 0.2rem;
        text-decoration-thickness: 0.2rem;
        color: $colorContrast;
        display: block;
        background-color: transparent;
        border: 0;

        #{$root}--Checked & {
            color: $colorWhite;
            text-decoration-color: $colorWhite;
        }
    }

    &__ComparisonPrice {
        font-size: 1.2rem;
        margin-top: 12px;
        width: 100%;
    }

    &__SignupButton {
        display: inline-block;
        align-self: center;
        transform: translateY(-50%);
    }

    &__RichText {
        @extend %rich-text;
        color: $colorContrast;
    }
}
