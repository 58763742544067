@import 'styles/includes';

.CardOptional {
    $root: &;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &--Vertical {
        @include media(m) {
            display: grid;
            grid-template-columns: 180px 1fr;
            position: relative;
        }

        @include media(ml) {
            grid-template-columns: 202px 1fr;
        }
    }

    &--FullHeight {
        height: 100%;
    }

    &__Container {
        padding: 0 16px 19px 16px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 0.8rem 0.8rem;
        border-right: 1px solid $colorBlue;
        border-bottom: 1px solid $colorBlue;
        border-left: 1px solid $colorBlue;

        #{$root}--Checked & {
            background-color: $colorBlue;
        }

        #{$root}--Vertical & {
            @include media(m) {
                padding: 0;
                border-radius: 0 0.8rem 0.8rem 0;
                border-top: 1px solid $colorBlue;
                border-right: 1px solid $colorBlue;
                border-bottom: 1px solid $colorBlue;
                border-left: 0;
            }
        }
    }

    &__Header {
        margin: 0;
        padding: 19px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $colorWhite;
        border-radius: 0.8rem 0.8rem 0 0;
        border-top: 1px solid $colorBlue;
        border-right: 1px solid $colorBlue;
        border-left: 1px solid $colorBlue;

        #{$root}--Vertical & {
            @include media(m) {
                padding: 1rem;
                border-radius: 0.8rem 0 0 0.8rem;
                border-top: 1px solid $colorBlue;
                border-right: 0;
                border-left: 1px solid $colorBlue;
                border-bottom: 1px solid $colorBlue;
            }
        }
    }

    &__Title {
        @extend %h4;
        color: $colorBlue;
    }

    &__Content {
        padding-top: 19px;
        color: $colorContrast;
        border-top: 1px solid $colorYellow;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        flex-grow: 1;

        #{$root}--Checked & {
            color: $colorWhite;
            border-color: transparent;
        }

        #{$root}--Checked#{$root}--Vertical & {
            border-left: 1px solid transparent;
        }

        #{$root}--Vertical & {
            @include media(m) {
                margin: 1rem 0;
                padding: 0 1rem;
                border-top: 0;
                border-left: 0.1rem solid $colorYellow;
            }

            @include media(ml) {
                padding: 0 1rem 0 2.4rem;
            }
        }
    }

    &__Text {
        @extend %rich-text;

        p {
            @extend %small;
            color: $colorContrast;

            #{$root}--Checked & {
                color: $colorWhite;
            }
        }
    }

    &__Footer {
        margin-top: 34px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        #{$root}--Vertical & {
            @include media(m) {
                margin-top: 1.4rem;
            }
        }
    }

    &__Button {
        @extend %p;
        padding: 0;
        text-decoration-line: underline;
        background-color: transparent;
        border: 0;
    }

    &__Price {
        @extend %p;
        color: $colorBlue;

        #{$root}--Checked & {
            color: $colorWhite;
        }
    }

    &__Label {
        @extend %h4;
        color: $colorBlue;

        #{$root}--Disabled & {
            color: $colorGrey-60;
        }
    }

    &__Checkbox {
        width: 100%;
        position: relative;
        display: inline-block;
        padding-left: 3.4rem;

        #{$root}--Vertical & {
            padding-left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__Input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__ControlIndicator {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 4px;
        border: 2px solid $colorBlue;
        background-color: $colorWhite;
        background-size: 1.5rem;
        background-position: center center;
        background-repeat: no-repeat;
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;

        #{$root}--Disabled & {
            border: 2px solid $colorGrey-60;
        }

        #{$root}--Vertical & {
            margin-right: 2rem;
            position: relative;
            flex-shrink: 0;
        }
    }

    &__Input:focus ~ #{$root}__ControlIndicator {
        box-shadow:
            0 0 0 0.075rem $colorWhite,
            0 0 0 0.2rem #0074d9;
    }

    &__Input:checked ~ #{$root}__ControlIndicator {
        color: $colorWhite;
        background-color: $colorBlue;
    }

    &__Input:active ~ #{$root}__ControlIndicator {
        color: $colorWhite;
        background-color: $colorBlue;

        #{$root}--Disabled & {
            background-color: $colorWhite;
        }
    }

    &__Input:checked ~ #{$root}__ControlIndicator {
        background-image: url('/img/check-white.svg');
    }

    &__RichText {
        @extend %rich-text;
        color: $colorContrast;
    }
}
