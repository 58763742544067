@import 'styles/includes';

.FormRadioButtonField {
    &__Title {
        @extend %h5;
        margin: 0 0 8px;
        color: $colorBlue;

        .FormRadioButtonField--Required & {
            &::after {
                content: '*';
                color: $colorError;
            }
        }
    }

    &__List {
        display: flex;
        flex-direction: column;

        &--Row {
            flex-direction: row;
            flex-flow: wrap;

            .FormRadioButtonField__Item {
                margin-right: 24px;
            }
        }
    }

    &__Item {
        margin-top: 8px;
    }

    &__Label {
        font-size: 1.6rem;
        color: $colorBlue;
        line-height: 2.4rem;
    }

    &__Radio {
        position: relative;
        display: inline-block;
        padding-left: 3.4rem;
        color: #555;
    }

    &__Input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__ControlIndicator {
        width: 2.4rem;
        height: 2.4rem;
        display: block;
        border: 2px solid $colorBlue;
        border-radius: 50%;
        background-color: $colorWhite;
        background-size: 1.2rem;
        background-position: center center;
        background-repeat: no-repeat;
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__Input:focus ~ .FormRadioButtonField__ControlIndicator {
        box-shadow: 0 0 0 0.075rem $colorWhite, 0 0 0 0.2rem #0074d9;
    }

    &__Input:checked ~ .FormRadioButtonField__ControlIndicator {
        background-color: $colorBlue;
    }

    &__Input:active ~ .FormRadioButtonField__ControlIndicator {
        background-color: $colorBlue;
    }


    &__Input:checked ~ .FormRadioButtonField__ControlIndicator {
        background-image: url('/img/check-white.svg');
    }

    &__HelpText {
        @extend %help-text;

        .FormRadioButtonField--Error & {
            color: $colorError;
        }
    }
}
