@import 'styles/includes';

.HeroWithButtons {
    position: relative;
}

.Hero {
    &__OffsetContent {
        @extend %container;
        margin-top: -36px;
        position: relative;

        @include media(m) {
            margin-top: -62px;
        }

        &--Subtitle {
            margin-top: -74px;

            @include media(m) {
                margin-top: -106px;
            }
        }
    }

    &__Title {
        @extend %h4;
        margin-bottom: 10px;
        color: $colorWhite;

        @include media(m) {
            margin-bottom: 18px;
        }
    }

    &__ButtonContainer {
        display: grid;
        gap: 15px;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }

        @include media(ml) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
