@import 'styles/includes';

.CardWithAnimatedIcon {
    padding: 20px;
    height: 100%;
    background-color: $colorBlue;
    border-radius: 8px;
    box-shadow: 0 0 0 0 $colorYellow;
    color: $colorWhite;
    letter-spacing: normal;
    transition: box-shadow $transition;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &:hover {
        box-shadow: 11px -11px 0px 0px $colorYellow;
    }

    &:focus-visible {
        outline: $colorYellow solid 3px;
        box-shadow: 11px -11px 0px 0px $colorYellow;
    }

    &--Center {
        align-items: center;
    }

    &--Top {
        align-items: flex-start;
    }

    &--Bottom {
        align-items: flex-end;
    }

    &__AnimatedIcon {
        margin: 0 15px 0 0;
        width: 100%;
        max-width: 75px;
        height: 100%;
        max-height: 75px;
        flex-shrink: 0;
    }

    &__SubTitle {
        margin-top: 0.5rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.6rem;

    }

    &__Headline {
        display: flex;
    }

    &__Title {
        @extend %h4;
        margin-right: 1rem;
        word-break: break-word;
    }
}
