@import 'styles/includes';

.FormDatePickerField {
    position: relative;

    &--Disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &__Label {
        @extend %label;
        margin: 0 -4px;
        padding: 2px 4px 0;
        background-color: $colorWhite;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: absolute;
        top: 0;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:
            top $transitionDelayFast $ease,
            left $transitionDelayFast $ease;

        .FormDatePickerField--Focused & {
            background-color: $colorLichen;
        }

        .FormDatePickerField--LargeLabel & {
            font-family: $fontBold;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            display: inline-block;
            position: relative;
            left: 10px;
            transition: none;
        }
    }

    &__Input {
        @extend %p;
        width: 100%;
        margin-top: 8px;
        padding: 16px 20px;
        letter-spacing: 1px;
        color: $colorBlue;
        border-radius: 8px;
        border: 1px solid $colorBlue;

        @include media-max(s) {
            font-size: 1.6rem;
        }

        .FormDatePickerField--Focused & {
            background-color: $colorLichen;
        }
    }

    &__HiddenField {
        display: none;
    }

    &__HelpText {
        @extend %help-text;

        .FormDatePickerField--Error & {
            color: $colorError;
        }
    }
}
