@import 'styles/includes';

.EstimatedConsumption {
    $root: &;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 0;

    @include media(s) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @include media(m) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media(ml) {
        flex-wrap: nowrap;
        display: flex;
    }

    &--Form {
        @include media(s) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(ml) {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    &__HousingType {
        width: 100%;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 700;
        color: $colorBlue;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include media(ml) {
            width: auto;
            flex-shrink: 0;
        }

        #{$root}--Form & {
            @include media(s) {
                grid-column: -1/1;
            }
        }
    }

    &__Type {
        margin-left: 16px;
    }

    &__AreaInput {
        width: 100%;

        @include media(ml) {
            width: 124px;
            flex-shrink: 0;
        }
    }

    &__ConsumptionInput {
        position: relative;
        width: 100%;

        @include media(ml) {
            width: 183px;
            flex-shrink: 0;
        }
    }

    &__RangeSlider {
        width: 100%;
    }

    &__RangeSliderMobile {
        width: 100%;

        @include media(s) {
            grid-column: -1/1;
        }

        @include media(m) {
            display: none;
        }
    }

    &__RangeSliderDesktop {
        display: none;

        @include media(m) {
            width: auto;
            display: block;
        }
    }

    &__InputRange {
        margin: 11px 0;
        min-width: 172px;
        width: 100%;
        height: 8px;
        background-color: $colorWhite;
        background-image: linear-gradient($colorBlue, $colorBlue);
        background-repeat: no-repeat;
        border: 0.05rem solid $colorBlue;
        border-radius: 8px;
        -webkit-appearance: none;

        @include media(ml) {
            min-width: 272px;
        }

        &::-webkit-slider-thumb {
            width: 30px;
            height: 30px;
            background-color: $colorBlue;
            border-radius: 50%;
            -webkit-appearance: none;
        }

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }
    }

    &__Discount {
        @extend %small;
        margin-top: 5px;
        padding: 7px 15px;
        color: $colorContrast;
        border-radius: 8px;
        background-color: $colorYellow;
        width: 100%;
        flex-shrink: 2;

        a {
            border-bottom: 1px;
            border-style: solid;
            border-color: inherit;

            &:hover {
                border-color: transparent;
            }
        }

        @include media(m) {
            width: calc(25% - 20px);
            order: 2;
        }

        @include media(ml) {
            width: auto;
        }

        #{$root}--Form & {
            @include media(s) {
                grid-column: -1/1;
            }

            @include media(m) {
                width: 100%;
                grid-column: 2;
            }
        }
    }

    &__Button {
        margin: 0 auto;
        grid-column: -1/1;

        @include media(ml) {
            margin: auto 0 auto auto;
        }

        &--Hide {
            @include media-max(ml) {
                display: none;
            }
        }
    }
}

.InputField {
    @extend %p;
    margin-top: 8px;
    padding: 16px 20px;
    width: 100%;
    letter-spacing: 1px;
    color: $colorBlue;
    border-radius: 8px;
    border: 1px solid $colorBlue;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    position: relative;
    background-color: #fff;

    @include media-max(s) {
        font-size: 1.6rem;
    }

    &--Focused {
        outline-offset: -1px;
    }

    &__Label {
        @extend %label;
        margin: 0 -4px;
        padding: 2px 4px 0;
        background-image: linear-gradient(
            to bottom,
            transparent 50%,
            #fff 50.001%
        );
        position: absolute;
        top: -10px;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__LabelInner {
        position: relative;
        z-index: 1;
    }

    &__InputField {
        @extend %p;
        padding: 0;
        width: 100%;
        letter-spacing: 1px;
        color: $colorBlue;
        background-color: transparent;
        border: 0;

        @include media-max(s) {
            font-size: 1.6rem;
        }

        &:focus,
        &:focus-visible {
            outline: none;
        }

        .EstimatedConsumption__SizeInput--Focused & {
            background-color: $colorLichen;
        }
    }

    input[type='number'] {
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        appearance: textfield;
        -moz-appearance: textfield;
    }
}
