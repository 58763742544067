@import 'styles/includes';

.MyPagesCustomerServiceBanner {
    $root: &;

    padding: 1.6rem 0;
    font-family: $fontBold;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.04rem;
    color: $colorWhite;
    text-align: center;
    background-color: $colorBlue;
    position: sticky;
    top: 0;
    z-index: 9000;

    &__Container {
        @extend %container;
    }
}
