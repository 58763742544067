@import 'styles/includes';

.FormAddressInputField {
    $root: &;

    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-height: max-content;
    flex-shrink: 0;

    &--HasLabel {
        margin-top: 8px;
        height: 68px;
    }

    &__Label {
        @extend %label;
        margin: 0 -4px;
        padding: 2px 4px 0;
        background-color: $colorWhite;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: absolute;
        top: 0;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        #{$root}--HasLabel & {
            top: -9px;
        }

        &:empty {
            display: none;
        }
    }
}
