@import 'styles/includes';

.MenuCard {
    background-color: $colorLichen;
    padding: 20px;
    width: 100%;

    &__Title {
        @extend %h4;
        margin-bottom: 16px;
        padding-bottom: 14px;
        color: $colorBlue;
        border-bottom: 1px solid $colorYellow; 
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: $colorBlue;
    }
}
