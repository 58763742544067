@import 'styles/includes';

.LanguageSelect {
    $root: &;

    position: relative;
    display: inline-block;

    &__Button {
        margin: 0;
        padding: 12px 10px;
        font-size: 1.4rem;
        letter-spacing: 1px;
        color: $colorContrast;
        background-color: transparent;
        border-radius: 8px;
        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        #{$root}--IsExpanded & {
            border-radius: 8px 8px 0 0;
            border-top: 1px solid $colorContrast;
            border-right: 1px solid $colorContrast;
            border-bottom: 1px solid transparent;
            border-left: 1px solid $colorContrast;
        }
    }

    &__Title {
        position: relative;
        font-family: $fontBody;

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: transparent;
            display: block;
            position: absolute;

            #{$root}__Button:hover & {
                background-color: $colorContrast;

                #{$root}--IsExpanded & {
                    background-color: transparent;
                }
            }
        }
    }

    &__Icon {
        margin-left: 10px;

        #{$root}--IsExpanded & {
            transform: rotate(180deg);
        }
    }

    &__List {
        display: none;
        position: absolute;
        z-index: 9999;
        background-color: $colorWhite;
        width: 100%;
        border-radius: 0 0 8px 8px;
        border-right: 1px solid $colorContrast;
        border-bottom: 1px solid $colorContrast;
        border-left: 1px solid $colorContrast;

        #{$root}--IsExpanded & {
            display: block;
        }
    }

    &__ListItem {
        &:first-of-type {
            #{$root}--Link {
                padding: 0 10px 12px 10px;
            }
        }
    }

    &__Link {
        padding: 12px 10px;
        display: block;

        &:hover,
        &:focus {
            font-family: $fontBold;
            font-weight: 700;
        }
    }
}
