@import 'styles/includes';

.CardPricingList {
    display: grid;
    gap: 2rem;

    @include media(m) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(2, 1fr);
        align-items: start;

        &--SingleCard {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
