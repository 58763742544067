@import 'styles/includes';

.Select {
    &__Input {
        @extend %p;
        width: 100%;
        margin-top: 8px;
        padding: 16px 40px 16px 20px;
        letter-spacing: 1px;
        color: $colorBlue;
        border-radius: 8px;
        border: 1px solid $colorBlue;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url('/img/chevron.svg');
        background-size: 12px 7px;
        background-repeat: no-repeat;
        background-position: center right 20px;
        background-color: transparent;
        border-style: solid;

        .Select--Error & {
            color: $colorError;
        }
    }

    &__Title {
        @extend %h5;
        margin: 0 0 8px;
        color: $colorBlue;

        .Select--Required & {
            &::after {
                content: '*';
                color: $colorError;
            }
        }
    }

    &__Title {
        @extend %h5;
        margin: 0 0 8px;
        color: $colorBlue;

        .Select--Required & {
            &::after {
                content: '*';
                color: $colorError;
            }
        }
    }

    &__HelpText {
        @extend %help-text;

        .Select--Error & {
            color: $colorError;
        }
    }
}
