@import 'styles/includes';

.Button {
    background-color: $colorWhite;
    border-radius: 6rem;
    border-color: transparent;
    display: inline-block;
    font-family: $fontBold;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    transition:
        $transition background-color,
        $transition color,
        $transition border;

    &:focus-visible {
        outline-offset: 1px;
        outline: $colorFocusBlue solid 3px;
    }

    &--Primary {
        padding: 1.6rem 3.2rem 1.4rem;
        color: $colorBlue;
        border: 2px solid $colorBlue;

        @include media(m) {
            padding: 1.6rem 4rem 1.4rem;
        }

        &:hover:not([disabled]) {
            background-color: $colorBlue;
            color: $colorWhite;
        }
    }

    &--Inverted {
        &:hover {
            border: 2px solid $colorWhite;
        }
    }

    &--Secondary {
        padding: 16px 30px 14px;
        border: 2px solid $colorYellow;
        color: $colorContrast;

        &:hover:not([disabled]) {
            color: $colorContrast;
            background-color: $colorYellow;
        }
    }

    &--Profile {
        background-color: $colorBlue;
        color: $colorWhite;
        letter-spacing: 0.025rem;
        padding: 11px 20px;
        text-transform: none;
    }

    &--Blue {
        padding: 16px 30px 14px;
        border: 2px solid transparent;
        background-color: $colorBlue;
        color: $colorWhite;

        &:hover:not([disabled]) {
            background-color: transparent;
            border: 2px solid $colorBlue;
            color: $colorBlue;
        }
    }

    &--YellowShadow {
        box-shadow: 0 0 0 0 $colorYellow;
        transition: box-shadow $transition;

        &:hover {
            box-shadow: 5px -5px 0px 0px $colorYellow;
        }
    }

    &--Active {
        color: $colorWhite;
        background-color: $colorBlue;
    }

    &--NoWrap {
        white-space: nowrap;
    }

    &--FullWidth {
        width: 100%;
    }

    &--Disabled,
    &[disabled] {
        background-color: $colorGrey-30;
        border-color: $colorGrey-30;
        color: $colorGrey-50;
        box-shadow: none;
        pointer-events: none;
    }

    &__Inner {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.4rem;
    }

    &__Inner--Reverse {
        flex-direction: row-reverse;
        gap: 0.8rem;
    }

    &:active {
        box-shadow: none;
    }
}
