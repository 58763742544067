@import 'styles/includes';

.GlobalNotice {
    $root: &;
    padding: 24px 0;
    background-color: $colorGrey-30;
    display: none;

    &--High {
        background-color: $colorYellow;
    }

    &--Visible {
        display: block;
    }

    &__Container {
        @extend %container;
        position: relative;
    }

    &__Content {
        padding-right: 60px;

        @include media(m) {
            padding-right: 100px;
        }
    }

    &__RichText {
        @extend %rich-text;
        color: $colorContrast;
        margin-bottom: 1.2rem;

        * {
            &:not(:last-child, h1, h2, h3, h4, h5, h6, li) {
                margin-bottom: 1.2rem;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $colorContrast;
        }

        p {
            @extend %small;
        }

        ul li {
            @extend %small;

            @include media(m) {
                padding-left: 1.8rem;
            }

            &::before {
                content: '';
                width: 0.6rem;
                height: 0.6rem;

                #{$root}--High & {
                    background-color: $colorContrast;
                }
            }
        }
    }

    &__Link {
        @include media-max(s) {
            padding: 1.45rem 0;
        }

        &:not(:last-of-type) {
            margin-right: 28px;
        }

        &:hover {
            .GlobalNotice__LinkText::after {
                width: 50%;
            }
        }
    }

    &__LinkText {
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.05rem;
        color: $colorContrast;
        position: relative;
        border-bottom: 2px solid $colorContrast;
        transition: border-color $transition;

        &:hover {
            border-color: transparent;
        }
    }

    &__Button {
        padding: 14px;
        background-color: $colorBlue;
        border-radius: 58px;
        display: inline-block;
        border: 0;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-70%);
    }
}
