@import 'styles/includes';

.Footer {
    width: 100%;
    padding: 80px 0 85px;
    background-color: $colorBlue;
    border-radius: 8px 8px 0 0;
    color: $colorWhite;

    *:focus {
        outline-color: $colorYellow;
    }

    @include media(m) {
        padding: 80px 0 72px;
    }

    &__Container {
        @extend %container;
    }

    &__Inner {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
    }

    &__Nav {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        flex-wrap: wrap;

        @include media(ml) {
            width: 75%;
            order: 1;
        }
    }

    &__Column {
        width: 100%;

        &:nth-last-of-type(-n + 1) {
            .Footer__List {
                margin: 0;
            }
        }

        @include media(xs) {
            width: 50%;
            padding-right: 15px;

            &:nth-last-of-type(-n + 2) {
                .Footer__List {
                    margin: 0;
                }
            }
        }

        @include media(m) {
            width: 25%;
        }

        @include media(ml) {
            width: auto;
            padding-right: 70px;
        }

        &:last-child {
            padding: 0;
        }
    }

    &__Title {
        @extend %h4;
        margin: 0 0 8px 0;
        font-family: $fontBlack;
        font-weight: 900;

        a {
            transition: color $transition;

            &:hover {
                color: $colorYellow;
            }
        }
    }

    &__List {
        margin: 0 0 10px 0;

        @include media(m) {
            margin: 0;
        }
    }

    &__Item {
        margin: 4px 0;

        @include media(m) {
            margin: 0;
        }
    }

    &__Link {
        @extend %small;
        padding: 4px 0;
        display: block;
        text-decoration-line: underline;

        &:hover {
            text-decoration-line: none;
        }

        @include media(m) {
            padding: 0;
            display: block;
        }
    }

    &__Info {
        margin: 27px 0 44px;
        padding: 32px 0 0;
        width: 100%;
        border-top: 1px solid $colorWhite;
        display: grid;
        @include media(s) {
            gap: 20px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(ml) {
            order: 3;
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
        }
    }

    &__Contact {
        @include media(ml) {
            display: flex;
            gap: 1rem;
        }
    }

    &__Label {
        margin-bottom: 1rem;
        font-family: $fontBlack;
        font-weight: 900;
        font-size: 1.2rem;
        line-height: 2;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__ContactList {
        margin-bottom: 12px;

        &:last-child {
            margin-top: 12px;
        }

        @include media(ml) {
            margin-bottom: 0;
            &:last-child {
                margin-top: 0;
                padding-left: 72px;
            }
        }
    }

    &__ContactItem {
        display: flex;
        @extend %small;
        line-height: 1.83rem;

        &:not(:last-child) {
            margin-bottom: 12px;
            @include media(s) {
                margin-bottom: 19px;
            }
        }
    }

    &__ContactInfo {
        text-decoration-line: underline;

        &:hover {
            text-decoration-line: none;
        }
    }

    &__Social {
        @include media-max(s) {
            &:last-child {
                margin-top: 20px;
            }
        }
    }

    &__SocialItem {
        display: flex;
    }

    &__IconLink {
        margin-right: 24px;

        &:last-child {
            margin-right: 10px;
        }
    }

    &__CookieSettings {
        width: 100%;
        margin-bottom: 44px;
        flex-grow: 1;

        @include media(ml) {
            margin-top: 55px;
            margin-bottom: 0;
            order: 3;
        }
    }

    &__Button {
        @extend %small;
        padding: 0;
        background-color: transparent;
        border: 0;
        text-decoration-line: underline;

        &:hover {
            text-decoration-line: none;
        }
    }

    &__Logo {
        margin: 0 auto;
        width: 152px;
        height: 45px;
        display: block;
        background-image: url('/img/logo-jamtkraft-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}
