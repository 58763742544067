@import 'styles/includes';

.ArticleHeader {
    &__Date {
        @extend %small;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.01rem;
        color: $colorBlue;
        margin-bottom: 1rem;
    }
}
