@import 'styles/includes';

.CardSignup {
    $root: &;

    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    padding: 16px;
    background: $colorGradient;

    @include media(m) {
        padding: 32px;
    }

    &--ProductCalculator {
        padding: 0;
        background: transparent;
    }

    &__Title {
        @extend %h3;
        margin-bottom: 16px;
        color: $colorBlue;
    }

    &__Text {
        @extend %small;
        margin-bottom: 18px;
        color: $colorContrast;

        #{$root}--ProductCalculator & {
            max-width: 420px;
        }
    }

    &__Grid {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 10px;

        @include media(m) {
            gap: 24px;
            justify-content: flex-start;
            align-items: stretch;
        }
    }

    &__InputField {
        width: 100%;
        margin: 0 0 15px 0;

        @include media(m) {
            margin: 0;
        }

        @include media(ml) {
            align-self: flex-end;
            width: 100%;
            max-width: 324px;
        }
        &:has(input:focus-within) {
            border-radius: 2px;
            outline-offset: 1px;
            outline: $colorFocusBlue solid 3px;
        }
    }

    &__ButtonContainer {
        margin: 0 0 1.6rem 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        width: 100%;

        @include media(m) {
            margin: 0;
            flex-grow: 1;
            width: auto;
        }
    }

    &__Button {
        padding: 14px;
        width: 100%;
        font-size: 1.2rem;
        font-family: $fontBold;
        font-weight: 700;
        letter-spacing: 0.02rem;
        color: $colorBlue;
        word-break: break-word;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $colorWhite;
        border: 1px solid $colorBlue;
        border-radius: 0.8rem;
        box-shadow: 0 0 0 0 $colorYellow;
        transition:
            box-shadow $transition,
            background-color $transition,
            color $transition;

        @include media-max(xs) {
            font-size: 1rem;
        }

        @include media(m) {
            padding: 16px 14px;
            font-size: 1.6rem;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
        &:focus,
        &:hover {
            color: $colorWhite;
            background-color: $colorBlue;
            box-shadow: 9px -9px 0px 0px $colorYellow;
        }

        &--Active {
            color: $colorWhite;
            background-color: $colorBlue;
            box-shadow: 9px -9px 0px 0px $colorYellow;
        }
    }

    &__Icon {
        margin: 0 0 10px 0;
        width: 32px;
        height: 38px;
        flex-shrink: 0;

        @include media(m) {
            margin: 0 16px 0 0;
        }
    }

    &__NextButton {
        text-align: center;
        align-self: center;

        @include media(m) {
            text-align: left;
        }
    }
}
