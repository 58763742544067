@import 'styles/includes';

.Dialog {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 55, 66, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 99999999;
    color: $colorContrast;

    &__Content {
        margin: 1.6rem;
        padding: 3.2rem 2rem;
        width: 100%;
        max-width: 742px;
        background-color: $colorLichen;

        @include media(m) {
            padding: 4rem;
        }
    }

    &__CloseButton {
        margin-left: auto;
        padding: 0;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: $colorBlue;
        border: 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Icon {
        margin-left: 18px;
    }

    &__Title {
        @extend %h3;
        margin: 0 0 16px;
        color: $colorBlue;
    }

    &__RichText {
        @extend %rich-text;
        color: $colorContrast;
    }

    &__ButtonContainer {
        margin: 32px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Button {
        margin: 0 16px;
    }

    &__BottomContent {
        a {
            text-decoration: underline;
        }

        p {
            @extend %small;
        }
    }
}
