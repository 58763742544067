@import 'styles/includes';

.Hero {
    height: 494px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $colorContrast;
    color: $colorWhite;
    position: relative;

    @include media(m) {
        min-height: 620px;
    }

    &--Slim {
        @include media(m) {
            min-height: 420px;
        }
    }

    &__BackgroundImage {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &__Gradient {
        background-image: linear-gradient(
            to top,
            #000 0%,
            rgba(75, 75, 75, 0) 75%
        );
        position: absolute;
        inset: 0;

        .Hero--Slim & {
            background-image: linear-gradient(
                to top,
                #000000 1.55%,
                rgba(75, 75, 75, 0) 95.61%
            );
        }
    }

    &__FadeIn {
        opacity: 0;
        transition: opacity $transitionDelaySlow;

        &--Loaded {
            opacity: 1;
        }
    }

    &__Inner {
        @extend %container;
        padding-top: 60px;
        padding-bottom: 60px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        position: relative;

        .Hero--WithOffsetContent & {
            padding-bottom: 60px;

            @include media(m) {
                padding-bottom: 118px;
            }
        }

        .Hero--Subtitle & {
            padding-bottom: 98px;

            @include media(m) {
                padding-bottom: 156px;
            }
        }
    }

    &__Title {
        @extend %h1;
        margin-bottom: 2rem;
        letter-spacing: -0.0496rem;

        b {
            @extend %curved-border;
        }
    }

    &__Text {
        @extend %rich-text;
    }
}
