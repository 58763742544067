@import 'styles/includes';

.FormCheckboxField {
    &__Title {
        @extend %h5;
        margin: 0 0 8px;
        color: $colorBlue;

        .FormCheckboxField--Required & {
            &::after {
                content: '*';
                color: $colorError;
            }
        }
    }

    &__List {
        display: flex;
        flex-direction: column;

        .FormCheckboxField--Row & {
            flex-direction: row;
            flex-flow: wrap;

            .FormCheckboxField__Item {
                margin-right: 24px;
            }
        }
    }

    &__Item {
        margin-top: 8px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__Label {
        @extend %rich-text;
        color: $colorBlue;
        line-height: 2.4rem;

        a[href$='.pdf'],
        a[href$='.doc'],
        a[href$='.docx'],
        a[target='_blank'] {
            &:after {
                content: none;
            }
        }
    }

    &__Checkbox {
        position: relative;
        display: inline-block;
        padding-left: 3.4rem;
    }

    &__Input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__ControlIndicator {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 4px;
        border: 2px solid $colorBlue;
        background-color: $colorWhite;
        background-size: 1.5rem;
        background-position: center center;
        background-repeat: no-repeat;
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__Input:focus ~ .FormCheckboxField__ControlIndicator {
        box-shadow: 0 0 0 0.075rem $colorWhite, 0 0 0 0.2rem #0074d9;
    }

    &__Input:checked ~ .FormCheckboxField__ControlIndicator {
        color: $colorWhite;
        background-color: $colorBlue;
    }

    &__Input:active ~ .FormCheckboxField__ControlIndicator {
        color: $colorWhite;
        background-color: $colorBlue;
    }

    &__Input:checked ~ .FormCheckboxField__ControlIndicator {
        background-image: url('/img/check-white.svg');
    }

    &__HelpText {
        @extend %help-text;

        .FormCheckboxField--SmallLabel & {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }

        .FormCheckboxField--Error & {
            color: $colorError;
        }

        .FormCheckboxField--SmallLabel.FormCheckboxField--Error & {
            @extend %help-text;
            color: $colorError;
        }
    }
}
