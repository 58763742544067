@import 'styles/includes';

.Hero {
    background-color: $colorLichen;
    color: $colorBlue;
    overflow-x: hidden;

    &--Gradient {
        background-image: linear-gradient(
            64deg,
            #fff8c4 4%,
            $colorLichen 47%,
            #e2ebe6 97%
        );
    }

    &--Blue {
        background-color: $colorBlue;
        color: $colorWhite;
    }

    &__Inner {
        @extend %container;
        height: 100%;

        @include media(ml) {
            display: flex;
            min-height: 420px;
        }
    }

    &__Content {
        padding: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        @include media(ml) {
            height: auto;
            width: 57.412%;
            padding: 72px 32px 72px 0;
            order: 0;
        }
        .Hero--Badges & {
            margin: 0 0 90px 0;
            padding: 20px 0 90px 0;

            @include media(ml) {
                margin: 0;
                padding: 72px 80px 72px 0;
            }
        }
    }

    &__Badges {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        transform: translateY(50%);

        @include media(ml) {
            z-index: 1;
            width: 140px;
            height: 100%;
            right: -70px;
            flex-direction: column;
            justify-content: flex-end;
            transform: translateY(0);
        }
    }

    &__Badge {
        margin: 0 10px;
        padding: 23px;
        width: 100px;
        height: 100px;
        position: relative;
        background: white;
        border-radius: 100%;
        border: 1px solid $colorBlue;
        flex-shrink: 0;

        @include media(s) {
            width: 140px;
            height: 140px;
        }

        @include media(ml) {
            margin: 0 0 10px 0;
        }
    }

    &__BadgeInner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__ImageContainer {
        position: relative;
        padding-bottom: 56.25%;

        @include media(ml) {
            width: 42.588%;
            padding-bottom: 0;
            order: 1;
        }
    }

    &__Title {
        @extend %h2;
        margin-bottom: 20px;

        b {
            @extend %curved-border;
        }
    }

    &__Text {
        @extend %h4;
        white-space: break-spaces;

        * {
            &:not(:last-child, li) {
                margin-bottom: 2rem;
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        b {
            font-weight: 700;
        }

        i {
            font-style: italic;
        }

        ul {
            @extend %ul;
        }

        p:empty {
            display: none;
        }
    }

    &__Button {
        margin: 30px 0 0 0;
    }

    &__Image {
        width: calc(100% + 3.2rem);
        height: 100%;
        position: absolute;
        left: -1.6rem;
        right: -1.6rem;

        @include media(ml) {
            width: calc(50vw - 17.412%);
            height: 100%;
            position: relative;
            left: 0;
            right: 0;
        }
    }
}
