@import 'styles/includes';

.Breadcrumbs {
    padding: 0.5rem 0;
    border-top: 1px solid #f1f5f3;
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: $colorContrast;
    position: relative;

    &::after {
        content: '';
        background: linear-gradient(
            270deg,
            $colorWhite 32.81%,
            rgba(255, 255, 255, 0) 92.31%
        );
        width: 5.6rem;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__Inner {
        @extend %container;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: scroll;
        white-space: nowrap;
        padding-right: 5rem;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__Link {
        font-weight: 600;
        color: $colorBlue;

        &::after {
            content: '/';
            padding: 0 0.5rem;
            font-weight: 400;
            display: inline;
        }
    }
}
